<template>
  <div class="articlePage">
    <div class="listMenu">
      <Dropdown trigger="click" @on-click="selMenu">
        <a href="javascript:void(0)">
          分类列表
          <Icon type="ios-arrow-down"></Icon>
        </a>
        <DropdownMenu slot="list">
          <DropdownItem v-for="(item, index) in alts" :key="index" :name="index">{{ item.name }}</DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </div>
    <div class="altsList">
      <div class="listCon">
        <div class="listItem" v-for="(item, index) in actAlts" :key="index">
          <img :src="item.url" alt="" v-if="item.type === 1" @click="openImgMod(item.url)"/>
          <video controls="controls" v-if="item.type === 2">
            <source :src="item.url"/>
          </video>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from "vuex";
  export default {
    computed: {
      ...mapGetters(["getConfig"])
    },
    data() {
      return {
        alts: [],
        itemAct: -1,
        actAlts: {
          atlas: {}
        },
        webSite: {},
        actUrl: ""
      };
    },
    created() {
      this.webSite = this.getConfig;
      this.alts = this.$route.name === "wapVideos" ? this.getConfig.videoType : this.getConfig.atlList;
      this.actAlts = this.$route.name === "wapVideos" ? this.getConfig.videos : this.getConfig.album;
    },
    watch: {
      getConfig() {
        this.webSite = this.getConfig;
        this.alts = this.$route.name === "wapVideos" ? this.getConfig.videoType : this.getConfig.atlList;
        this.actAlts = this.$route.name === "wapVideos" ? this.getConfig.videos : this.getConfig.album;
      }
    },
    mounted() {
      window.scrollTo(0, 0);
    },
    methods: {
      openImgMod(url) {
        this.actUrl = url;
      },
      getListAct(type) {
        if (type) {
          return {
            backgroundColor: this.getConfig.pageColor,
            color: "#fff"
          }
        }
      },
      linkToArticle(id) {
        this.$router.push({ name: "article", query: { id: id } });
      },
      getTime(time) {
        if (!time) {
          return "";
        }
        let d = new Date(time);
        let year = d.getFullYear();
        let month = d.getMonth() + 1;
        let date = d.getDate();
        // let hour = d.getHours();
        // let minute = d.getMinutes();
        // let second = d.getSeconds();
        return year + "/" + month + "/" + date;
      },
      checkTime(i) {
        if (i < 10) {
          i = "0" + i;
        }
        return i;
      },
      selMenu(idx) {
        this.itemAct = idx;
        this.actAlts = [];
        let arr = this.$route.name === "wapVideos" ? this.getConfig.videos : this.getConfig.album;
        for (let i of arr) {
          if (i.atlas === arr[idx].atlas) {
            this.actAlts.push(i)
          }
        }
      }
    }
  };
</script>
